<template>
  <div id="app" :class="hasBodyClassess">
    <nav
      class="navbar is-hidden-touch"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link to="/" @click="closeMenuChange">
          <img src="/images/default-monochrome-white.svg" width="120" />
        </router-link>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <router-link
            class="
              navbar-item
              is-uppercase
              has-text-left has-text-white
              pt-4
              pb-4
            "
            :to="'/?_t=' + getTimestamp"
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="home" /> Learn more
          </router-link>

          <router-link
            :to="'/start?_t=' + getTimestamp"
            class="
              navbar-item
              is-uppercase
              has-text-left has-text-white
              pt-4
              pb-4
            "
            v-if="getIsUserSetup"
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="piggy-bank" /> Penny Challenge
          </router-link>

          <router-link
            :to="'/privacy?_t=' + getTimestamp"
            class="
              navbar-item
              is-uppercase
              has-text-left has-text-white
              pt-4
              pb-4
            "
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="info-circle" /> Privacy</router-link>

          <router-link
            :to="'/terms?_t=' + getTimestamp"
            class="
              navbar-item
              is-uppercase
              has-text-left has-text-white
              pt-4
              pb-4
            "
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="file-text" /> Terms &amp; Conditions
          </router-link>

        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <router-link class="button is-primary" to="/start">
                <strong>Get Started</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <nav
      class="navbar is-hidden-tablet"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link to="/" @click="closeMenuChange">
          <img src="/images/default-monochrome-white.svg" width="120" />
        </router-link>
        <a
          role="button"
          class="navbar-burger has-text-white"
          @click="triggerMenuChange"
          :class="menuActiveClasses"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navBarPennyChallenge"
        class="navbar-menu mt-3"
        :class="menuActiveClasses"
      >
        <div class="navbar-start has-text-left">
          <router-link
            class="
              navbar-item
              nav-item-border-bottom
              is-uppercase
              has-text-left has-text-dark
              pt-4
              pb-4
            "
            :to="'/?_t=' + getTimestamp"
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="home" /> Learn more
            <span class="is-size-7 pl-5 is-block"
              >READ ABOUT THE CHALLENGE</span
            >
          </router-link>

          <router-link
            :to="'/start?_t=' + getTimestamp"
            class="
              navbar-item
              nav-item-border-bottom
              has-text-dark
              is-uppercase
              pt-4
              pb-4
            "
            v-if="getIsUserSetup"
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="piggy-bank" /> Penny Challenge
            <span class="is-size-7 pl-5 is-block">The 1p Challenge</span>
          </router-link>

          <router-link
            :to="'/privacy?_t=' + getTimestamp"
            class="
              navbar-item
              nav-item-border-bottom
              has-text-dark
              is-uppercase
              pt-4
              pb-4
            "
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="info-circle" /> Privacy
            <span class="is-size-7 pl-5 is-block">How we use your data</span>
          </router-link>

          <router-link
            :to="'/terms?_t=' + getTimestamp"
            class="
              navbar-item
              nav-item-border-bottom
              has-text-dark
              is-uppercase
              pt-4
              pb-4
            "
            @click="closeMenuChange"
          >
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="file-text" /> Terms &amp; Conditions
            <span class="is-size-7 pl-5 is-block">Important stuff</span>
          </router-link>

          <a class="navbar-item is-uppercase pt-4 pb-4" @click="logoutActions">
            <font-awesome-icon
              icon="chevron-right"
              class="is-pulled-right has-text-dark pt-3"
            />
            <font-awesome-icon icon="sign-out" /> Logout
            <span class="is-size-7 pl-5 is-block">See you again soon</span>
          </a>
        </div>
      </div>
    </nav>

    <Loading />

    <div class="container px-5">
      <div class="columns">
        <router-view />
      </div>
    </div>

    <footer class="footer mt-5 pt-5 has-text-centered has-text-white">
      <div v-if="showAdvert">
        <a href="https://bit.ly/1pfreetrade" target="_blank" class="has-text-white">
          <h2 class="is-size-3 is-underlined">Get a FREE share</h2>
          <p>Join and invest with Freetrade.<br />
            Get started with a free share worth £10. T&Cs apply. Capital at risk.</p>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/Loading.vue";

import lodash from "lodash";
import { useCookies } from "vue3-cookies";

export default {
  components: {
    Loading,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  mounted() {
    let myUserId = this.cookies.get("pennyUserId");
    if (myUserId !== null) {
      this.setUserId(myUserId);
      this.retrieveLogs();
    }
  },
  methods: {
    ...mapActions([
      "setUserId",
      "resetUserId",
      "retrieveLogs",
      "toggleMenu",
      "closeMenu",
      "openMenu",
    ]),
    closeMenuChange() {
      this.closeMenu();
    },
    triggerMenuChange(event) {
      this.toggleMenu();
    },
    logoutActions() {
      this.closeMenuChange();
      this.cookies.remove("pennyUserId");
      this.resetUserId();
    },
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getDaysLeft",
      "getBodyClass",
      "showAdvert",
      "getIsUserSetup",
      "getCompletedToday",
      "getMenuToggle",
    ]),
    hasUserId() {
      return !lodash.isEmpty(this.getUserId);
    },
    hasBodyClassess() {
      return this.getBodyClass;
    },
    challengeColorClasses() {
      return {
        "has-text-success": this.getCompletedToday,
        "has-text-danger": !this.getCompletedToday,
      };
    },
    menuActiveClasses() {
      return {
        "is-active": this.getMenuToggle,
      };
    },
    getTimestamp() {
      return Math.floor(Date.now() / 1000);
    },
  },
};
</script> 

<style lang="scss">
@import "assets/style";
</style>
