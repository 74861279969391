import axios from 'axios'

let BASE_URL = process.env.VUE_APP_API_DOMAIN
let AUTH_TOKEN = 'aaaa-bbbb-cccc'  

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common['X-Rapidapi-key'] = AUTH_TOKEN
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common['Pragma'] = 'no-cache'
axios.defaults.headers.common['Expires'] = '0'

export default {
    API_HOST: BASE_URL
}