<template>
  <div class="content">
    <div class="columns">
      <div class="column is-full has-text-left has-text-white">
        <h2 class="has-text-white mt-6 mb-1 pb-1">Start new savings challenge</h2>
        <p>Tailor the challenge to you.</p>
      </div>
      <div class="column is-full has-text-left">
        <label class="has-text-white pb-3">Start date</label>
        <div class="select is-normal is-fullwidth mt-2">
          <select v-model="startDate">
            <option value="today">Today ({{ todayDate }})</option>
            <option value="startofyear">
              Start of year ({{ startOfYearDate }})
            </option>
          </select>
        </div>
      </div>
      <div class="column is-full has-text-left">
        <p class="has-text-white">Your end date is {{ oneYearEndDate }}</p>
      </div>
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link" @click="saveSettings">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      startDate: "startofyear",
      endDate: "endofyear",
    };
  },
  mounted() {
    this.setBodyClass("challenge");
  },
  methods: {
    ...mapActions(["setBodyClass"]),
  },
  computed: {
    ...mapGetters([]),
    startDateObj() {
        if(this.startDate == "startofyear") {
            return moment().startOf("year")
        }
        return moment()
    },
    endDateObj() {
        if(this.endDate == "endofyear") {
            return moment().endOf("year")
        }
        return moment(this.startDateObj).add(1, year)
    },
    todayDate() {
      return moment().format("Do MMMM YYYY");
    },
    startOfYearDate() {
      return moment().startOf("year").format("Do MMMM YYYY");
    },
    oneYearEndDate() {
        return moment(this.startDateObj).add(1, "year").subtract(1, "day").format("Do MMMM YYYY");
    }
  },
};
</script>

<style>
</style>