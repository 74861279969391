const state = {
    loading: {
        active: false,
    },
}
const getters = {
    getIsLoading: state => state.loading.active,
}
const actions = {
    setIsLoading({
        context,
        state,
        commit
    }, newLoadingStatus) {
        commit('setIsLoading', newLoadingStatus)
    },
}
const mutations = {
    setIsLoading(state, newLoadingStatus) {
        state.loading.active = newLoadingStatus
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}