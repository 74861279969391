import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import config from '@/config'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHatWizard);

// Vue.component('font-awesome-icon', FontAwesomeIcon)

let APP_API_HOST = process.env.VUE_APP_API_HOST
let APP_AUTH_TOKEN = process.env.VUE_APP_RAPID_API_KEY

window._ = require('lodash');

require('bulma')
require('animate.css')
import 'maz-ui/css/main.css'

const app = createApp(App)

axios.defaults.baseURL = APP_API_HOST;
axios.defaults.headers.common['x-rapidapi-key'] = APP_AUTH_TOKEN;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
 
app.use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')