<template>
  <div class="home">
    <div class="has-text-left has-text-white mx-3 pt-6 pb-6">
      <h1 class="title has-text-white">Welcome!</h1>
      <p>
        The well-known 1p challenge is a money saving method that lasts 365
        days.
      </p>
      <p class="mt-2">
        It works by increasing the amount you save by 1p each day of the year.
        On the first day of the challenge, you save one penny.
      </p>
      <p class="mt-2">
        This rises to two pennies on the second day, three pennies on the third,
        four pennies on the fourth, and so on.
      </p>
      <p class="mt-2">
        On the last day of the year, which is the 365th day, you'll save £3.65.
      </p>
      <p class="mt-2">
        Once you put every day's savings together, it adds up to £667.95.
      </p>
      <router-link class="button is-fullwidth mt-3 is-primary" :to="'/start?_t='+getTimestamp" @click="closeMenuChange"
        >Get started</router-link
      >
    </div>
    <div class="box">
      <div class="columns is-mobile">
        <div class="column is-half">
          <span v-html="tracking.tracking.displayValue"></span>
          <p class="is-uppercase">
            <small>Total Users</small>
          </p>
        </div>
        <div class="column">
          <span v-html="tracking.savings.displayValue"></span>
          <p class="is-uppercase">
            <small>Savings Tracked</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import config from "@/config";

// @ is an alias to /src
export default {
  name: "HomeView",
  data() {
    return {
      tracking: {
        savings: {
          displayValue: '',
          description: ''
        }, 
        tracking: {
          displayValue: '',
          description: ''
        }
      },
    };
  },
  mounted() {
    this.setBodyClass('')
    axios.get(config.API_HOST + "/api/global-tracking").then((response) => {
      this.tracking = response.data.data;
    });
  },
  methods: {
    ...mapActions(['setBodyClass', 'closeMenu']),
    closeMenuChange() {
      this.closeMenu()
    }
  },
  computed: {
    ...mapGetters(["getChallengeYear"]),
    getTimestamp() {
      return Math.floor(Date.now() / 1000)
    }
  },
};
</script>
