<template>
  <div class="loader-wrapper" :class="hasLoadingClasses">
    <div class="loader is-loading"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Loading",
  mounted() {},
  computed: {
    ...mapGetters(["getIsLoading"]),
    hasLoadingClasses() {
      return {
        "is-active": this.getIsLoading,
      };
    },
  },
};
</script>

<style>
</style>