<template>
  <div class="content">
    <div class="columns mt-6">
      <div class="column is-full has-text-left has-text-white">
        <h2 class="has-text-white mb-1 pb-1 is-size-2">Settings</h2>
        <p>Tailor the challenge to you.</p>
      </div>
      <div class="column is-full has-text-left">
        <label class="has-text-white pb-3 is-size-5">Preferred currency</label>
        <div class="select is-normal is-fullwidth mt-2">
          <select v-model="defaultCurrency">
            <option value="GBP">£ GBP</option>
            <option value="USD">$ USD</option>
            <option value="EUR">&euro; EUR</option>
          </select>
        </div>
      </div>
      <div class="column is-full has-text-left">
        <label class="has-text-white pb-3 is-size-5">Start date</label>
        <p class="has-text-white is-size-6">Start the challenge on Jan 1st, or today*</p>
        <div class="select is-normal is-fullwidth mt-2">
          <select v-model="firstChallengeDate" class="start-dates">
            <option value="today" disabled>Today ({{ todayDate }})*</option>
            <option value="startofyear">
              {{ startOfYearDate }}
            </option>
          </select>
        </div>
        <p class="has-text-white is-size-7 text-left">* = Coming soon</p>
      </div>
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link" @click="saveSettings">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      errors: [],
      firstChallengeDate: "startofyear",
      defaultCurrency: "GBP",
    };
  },
  mounted() {
    this.setBodyClass("challenge")
  },
  methods: {
    ...mapActions(['retrieveLogs', 'setBodyClass']),
    resetErrors() {
      this.errors = [];
    },
    saveSettings() { 
      axios
        .post(config.API_HOST + "/api/user/" + this.getUserId + '/settings', { firstChallengeDate: this.firstChallengeDate, defaultCurrency: this.defaultCurrency })
        .then((response) => {
          this.resetErrors();
        })
        .finally(() => {
          this.retrieveLogs()
        });
    },
  },
  computed: {
    ...mapGetters(['getUserId']),
    todayDate() {
      return moment().format("Do MMMM YYYY");
    },
    startOfYearDate() {
      return moment().startOf("year").format("Do MMMM YYYY");
    },
  },
};
</script>

<style>
</style>