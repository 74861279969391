<template>
  <div class="column">
      <div class="challenge">
        <auth v-if="!hasUserId" />
        <setup v-if="hasUserId && !getIsUserSetup" />
        <penny-channenge v-if="hasUserId && getIsUserSetup" />
      </div>
  </div>
</template>

<script>
import PennyChannenge from '@/components/PennyChannenge.vue'
import Auth from '@/components/Auth.vue'
import Setup from '@/components/Setup.vue'
import { mapActions, mapGetters } from 'vuex'
import lodash from 'lodash'

export default {
  name: 'ChallengeView',
  components: {
    PennyChannenge,
    Auth,
    Setup
  },
  mounted() {
    if(this.hasUserId) {
      this.retrieveLogs()
    }
  },
  methods: {
    ...mapActions(['retrieveLogs'])
  },
  computed: {
    ...mapGetters(['getUserId', 'getIsUserSetup']),
    hasUserId() {
      return !lodash.isEmpty(this.getUserId)
    },
  }
}
</script>

<style>
.challenge {
  min-height: 600px
}
</style>