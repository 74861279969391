import ChallengeModule from '@/store/modules/challenge-module'
import LoadingModule from '@/store/modules/loading'
import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    styling: {
      bodyClass: '',
    },
    toggles: {
      menu: false
    }
  },
  getters: {
    getBodyClass: state => state.styling.bodyClass,
    getMenuToggle: state => state.toggles.menu
  },
  mutations: {
    setBodyClass(state, newBodyClass) {
      state.styling.bodyClass = newBodyClass
    },
    toggleMenu(state) {
      state.toggles.menu = !state.toggles.menu
    },
    closeMenu(state) {
      state.toggles.menu = false
    },
    openMenu(state) {
      state.toggles.menu = true
    }
  },
  actions: {
    setBodyClass({
      context,
      dispatch,
      commit
    }, newBodyClass) {
        commit('setBodyClass', newBodyClass) 
    },
    toggleMenu({
        context,
        state,
        commit
    }) {
        commit('toggleMenu') 
    },
    closeMenu({
        context,
        state,
        commit
    }) {
        commit('closeMenu') 
    },
    openMenu({
        context,
        state,
        commit
    }) {
        commit('openMenu') 
    },
  },
  modules: {
    ChallengeModule, 
    LoadingModule
  },
})