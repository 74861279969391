import axios from 'axios'
import moment from 'moment'
import config from '@/config'

const state = {
    userId: '',
    logs: {
        user: {
            completedToday: false,
            isSetup: false,
            daysLeft: 0,
            savedToDateDisplayValue: '£0.00',
            canShowAdvert: true,
        },
        calendar: {
            year: 2022,
            totalSavingsInThisMonthDisplayValue: '£0.00',
            totalSavingsInYearDisplayValue: '£0.00',
            days: {
                current: {
                    todayDisplayValue: '£0.00'
                },
                next: {
                    todayDisplayValue: '£0.00'
                },
            }
        }
    },
}
const getters = {
    showAdvert: state => state.logs.user.canShowAdvert,
    getUserId: state => state.userId,
    getChallengeYear: state => state.logs.calendar.year,
    getTodaySetAside: state => state.logs.calendar.days.current,
    getTomorrowSetAside: state => state.logs.calendar.days.next,
    getUser: state => state.logs.user,
    getChallengeCalendar: state => state.logs.calendar,
    getCompletedToday: state => state.logs.user.completedToday,
    getIsUserSetup  : state => state.logs.user.isSetup,
    getDaysLeft: state => state.logs.user.daysLeft,
}
const actions = {
    generateUserId({
        context,
        commit,
        dispatch
    }) {
        commit('setIsLoading', true)
        return axios.post(config.API_HOST + "/api/user").then(response => {
            let userId = response.data.data.userId
            commit('setUserId', userId)
            dispatch('retrieveLogs') 
        })
    },
    setUserId({
        context,
        dispatch,
        commit
    }, userId) {
        commit('setUserId', userId) 
    },
    resetUserId({context, commit}) {
        commit('resetUserId')
    },
    retrieveLogs({
        commit,
        state
    }) {
        commit('setIsLoading', true)
        return axios.get(config.API_HOST + "/api/user/" + state.userId + '?_t=' + moment().unix()).then(response => {
            commit('setLogs', response.data.data)
            commit('setIsLoading', false)
        }).finally(() => {
            commit('setIsLoading', false)
        }).catch(() => {
            commit('resetUserId')
            commit('setIsLoading', false)
        });
    },
    submitLog({
        commit,
        state,
        dispatch
    }, data) {
        axios.post(config.API_HOST + "/api/user/" + state.userId + '/log', {
            "date": data.date,
            "all": data.all ?? null
        }).then(response => {
            dispatch('retrieveLogs')
        }).finally(() => {});
    }
}
const mutations = {
    setUserId: (state, userId) => (
        state.userId = userId
    ),
    resetUserId(state) {
        state.userId = ''
    },
    resetLogs(state) {
        state.logs = {}
    },
    setLogs(state, data) {
        state.logs = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}