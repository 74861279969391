<template>
  <div class="content">

    <div class="columns">
      <div class="column is-full">
        <h3 class="has-text-center has-text-white mt-5">
          The <span class="text-penny-green">{{ getChallengeYear }}</span> 1p
          Savings Challenge<br />
          Saving you
          <span
            v-html="getTotalSaved"
            class="has-background-success-dark px-2 has-text-white"
          ></span
          ><br />
        </h3>
        <p class="mt-3 has-text-white">
          {{ getDaysLeft }} days remaining
        </p>
      </div>
    </div>

    <div class="columns is-multiline is-centered is-mobile">
      <div class="column px-1 py-1 is-full-mobile is-one-quarter-desktop">
        <div class="has-background-white p-3 box">
          <p class="title has-text-purple is-purple-title">
            This month, you <i>will</i> save
            <span
              v-html="getTotalSavedThisMonth"
              class="has-text-success"
            ></span>
          </p>
        </div>
      </div>
      <div class="column px-1 py-1 is-full-mobile is-one-quarter-desktop">
        <div class="has-background-white p-3 is-full is-one-quarter box">
          <p class="title has-text-purple is-purple-title">
            In total, you've logged
            <span v-html="getSavedToDate" class="has-text-success"></span>
          </p>
        </div>
      </div>
      <div class="column px-1 py-1 is-center is-one-quarter-desktop is-half-mobile">
        <div class="p-3 box has-text-dark" :class="hasCompletedTodayClass" @click.prevent="completeToday">
          <p class="subtitle has-text-white">
            Today <span v-if="getUser.completedToday"><a
                class="is-italic is-underlined"
                v-if="hasCompletedToday">Undo</a></span>
          </p>
          <p class="title has-text-white">
            <span v-html="getTodayDisplayValue" class=""></span>
          </p>
        </div>
      </div>
      <div class="column px-1 py-1 is-center is-one-quarter-desktop is-half-mobile">
        <div class="has-background-warning py-3 px-1 box">
          <p class="subtitle has-text-dark">
            Tomororow</p>
          <p class="title has-text-dark">
            <span
              v-html="getTomorrowDisplayValue"
            ></span>
          </p>
        </div>
      </div>
    </div>

    <!-- <span>
              <a
                class=""
                v-if="!hasCompletedToday"
                @click.prevent="completeToday"
                >Log&nbsp;<span v-html="getTodayDisplayValue"></span>
              </a>
              
            </span>-->

    <div v-if="hasLogs">
      <div class="mt-4 has-text-left" v-if="missingDates.length">
        <h3 class="has-text-white">Dates you've missed</h3>
        <div
          class="box p-2 mb-2"
          v-for="day in missingDates"
          :class="animationClass(day)"
        >
          <div class="columns is-mobile">
            <div
              class="column is-full-mobile is-two-thirds-tablet is-half-desktop py-2 px-3"
            >
              <label class="checkbox" @click.prevent="completeDate($event, day)">
                <input
                  type="checkbox"
                />
                {{ day.dateHuman }} for
                <span v-html="day.amount.todayDisplayValue"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="box mt-2 p-2 mb-2 has-background-primary" v-if="missingDates.length > 1">
          <div class="columns is-mobile">
            <div class="column py-2 px-3 is-full-mobile is-two-thirds-tablet is-half-desktop">
              <label class="checkbox" @click.prevent="catchUp">
                <input type="checkbox" /> Catch up with &nbsp;<span v-html="getUser.catchAmount"></span>
              </label> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { useCookies } from "vue3-cookies";

export default {
  components: {
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      checkboxChecked: false,
      current: 0,
    };
  },
  mounted() {  
    if (this.getUserId.length > 0) {
      this.retrieveLogs();
    }
    this.setBodyClass("challenge")
  },
  methods: {
    ...mapActions([
      "setUserId",
      "generateUserId",
      "retrieveLogs",
      "submitLog",
      "setIsLoading",
      "setBodyClass",
    ]),
    completeToday() {
      this.current = this.getTodaySetAside.date;
      this.setIsLoading(true);
      this.checkboxChecked = false;
      this.submitLog({ date: this.getTodaySetAside.date });
      this.current = ''
    },
    completeDate(e, day) {
      e.target.checked = "checked";
      this.current = day.date;
      this.setIsLoading(true);
      this.submitLog({ date: day.date });
      this.current = ''
    },
    catchUp() {
      this.setIsLoading(true);
      this.submitLog({ date: moment().format("YYYY-MM-D"), all: "yes" });
    },
    animationClass(day) {
      return {
        "animate__animated animate__fadeOut": this.current == day.date,
      };
    },
    doNothing() {},
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getChallengeYear",
      "getTodaySetAside",
      "getChallengeCalendar",
      "getTomorrowSetAside",
      "getUser",
      "getCompletedToday",
      "getDaysLeft",
      "getIsLoading",
    ]),
    hasCompletedTodayClass() {
      return {
        'has-background-danger': !this.getUser.completedToday,
        'has-background-success': this.getUser.completedToday,
      }
    },
    hasLogs() {
      return this.getChallengeYear.length > 0;
    },
    getSavedToDate() {
      return this.getUser.savedToDateDisplayValue;
    },
    getTodayDisplayValue() {
      return this.getTodaySetAside.todayDisplayValue;
    },
    getTomorrowDisplayValue() {
      return this.getTomorrowSetAside.todayDisplayValue;
    },
    hasCompletedToday() {
      return this.getCompletedToday;
    },
    getTotalSavedThisMonth() {
      return this.getChallengeCalendar.totalSavingsInThisMonthDisplayValue;
    },
    getTotalSaved() {
      return this.getChallengeCalendar.totalSavingsInYearDisplayValue;
    },
    missingDates() {
      return this.getUser.missing;
    },
  },
};
</script>

<style>
</style>