<template>
  <div class="content" style="margin-top: 200px">
    <div class="columns"> 
      <div class="column">
        <h3 class="has-text-left has-text-white mt-3">Get started</h3>
        <p class="has-text-left has-text-white mb-0 pb-0">Sign in with mobile phone number</p>
        <p class="has-text-left has-text-white is-size-7 is-italic">This is so you don't lose your progress</p>
        <article class="message is-danger" v-if="hasFormErrors">
          <div class="message-body">
            <p v-for="error in errors" :key="error">{{ error.message }}</p>
          </div>
        </article>
        <div class="field">
          <label class="label has-text-white has-text-left"
            >Mobile Number</label
          >
          <div class="control">
            <!-- <input
              class="input"
              type="tel"
              placeholder="Mobile number, +44.."
              v-model="mobileNumber"
            /> -->
            <MazPhoneNumberInput
              v-model="mobileNumber"
              :only-countries="['US', 'GB']"
              default-country-code='GB'
              show-code-on-list
              @update="results = $event"
              :success="results?.isValid"
              :translations="{
                countrySelector: {
                  placeholder: 'Country',
                  error: 'Choose country',
                },
              }"
              :custom-countries-list="{
                FR: 'France',
                US: 'USA',
                GB: 'UK'
              }"
            />
          </div>
        </div>
        <div class="field is-grouped" v-if="!hasRequestedCode">
          <div class="control">
            <button class="button is-link" @click="requestCode" :disabled="!results?.isValid">Submit</button>
          </div>
          <div class="control">
            <button class="button is-link is-light" @click="resetForm">
              Reset
            </button>
          </div>
        </div>
        <div v-if="hasRequestedCode">
          <div class="field">
            <label class="label has-text-white has-text-left">2FA Code</label>
            <div class="control">
              <input
                class="input"
                type="number"
                placeholder="000000"
                v-model="twoFactorCode"
              />
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button class="button is-link" @click="makeLogin">Login</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" @click="resetForm">
                Reset
              </button>
            </div>
            <div class="control">
              <p><a class="link has-text-white" href="/">Back to home</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns"> 
      <div class="column">
        <hr />
        <p class="has-text-white has-text-left">Or continue without saving phone number</p>
        <div class="buttons">
          <router-link class="button is-primary is-fullwidth" to="/start" @click="blankUser"> 
            <strong>Continue without</strong>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { mapActions } from "vuex";
import { useCookies } from "vue3-cookies";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
  components: {
    MazPhoneNumberInput
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      mobileNumber: "",
      twoFactorCode: "",
      requestedCode: false,
      errors: [],
      results: {
        valid: false
      },
    };
  },
  mounted() {
    this.requestedCode = false;
    this.setBodyClass("auth")
  },
  methods: {
    ...mapActions(["setUserId", "setBodyClass"]),
    resetErrors() {
      this.errors = [];
    },
    resetForm() {
      this.mobileNumber = "";
      this.twoFactorCode = "";
      this.requestedCode = false;
      this.resetErrors()
    },
    hasMadeCodeRequest() {
      this.requestedCode = true;
    },
    addError(errorMessage) {
      this.resetErrors();
      let errorItem = { message: errorMessage };
      this.errors.push(errorItem);
      return;
    },
    requestCode() {
      if (this.mobileNumber.length < 11) {
        return this.addError("Invalid mobile number");
      }

      axios
        .post(config.API_HOST + "/api/2fa", { mobileNumber: this.mobileNumber })
        .then((response) => {
          this.resetErrors();
          this.hasMadeCodeRequest();
        })
        .catch((error) => {
          return this.addError("Something went wrong. Try again");
        });
    },
    makeLogin() {
      if (this.twoFactorCode.length < 6) { 
        return this.addError("Invalid code");
      }
      axios
        .post(config.API_HOST + "/api/login", {
          mobileNumber: this.mobileNumber,
          twoFactorCode: this.twoFactorCode,
        })
        .then((response) => {
          let userId = response.data.data.user_id
          this.resetErrors();

          this.cookies.set("pennyUserId", userId, "720d", "/", "", false);
          this.setUserId(userId);
        })
        .catch((error) => {
          return this.addError("Something went wrong. Try again");
        });
    },
    blankUser() {
      axios
        .post(config.API_HOST + "/api/blank", {})
        .then((response) => {
          let userId = response.data.data.user_id
          this.resetErrors();

          this.cookies.set("pennyUserId", userId, "720d", "/", "", false);
          this.setUserId(userId);
        })
    }
  },
  computed: {
    hasRequestedCode() {
      return this.requestedCode;
    },
    hasFormErrors() {
      return this.errors.length > 0;
    },
  },
};
</script>

<style>
.m-phone-number-input__select {
  width: 7rem !important;
}
</style>